import { Chip } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import type { Adress } from '@willig/types/api'
import type { Record } from 'ra-core'
import React from 'react'
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  TextInput,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import GeoApiGouv from 'src/components/GeoApiGouv'

type CreateEditInputsProps = {
  record?: Record
}

export const CreateEditInputs = (props: CreateEditInputsProps) => {
  const { record } = props
  return (
    <Box display="flex" gridGap={16} flexDirection="column">
      <Card>
        <CardContent>
          <Box>{record?.pro_id && <Chip color="secondary" label="Pro" />}</Box>
          <Box>
            <GeoApiGouv source="rue" label="Rue" required />
          </Box>

          <Box display="flex" gridGap={16}>
            <TextInput fullWidth source="code_postal" required />
            <TextInput fullWidth source="ville" required />
          </Box>
          <Box display="flex" gridGap={16}>
            <ReferenceInput
              perPage={0}
              fullWidth
              label="Zone"
              source="zone"
              reference="Zone"
              filterToQuery={(searchText) => {
                return { label: searchText }
              }}
              validate={[required()]}
            >
              <AutocompleteInput optionText="label" />
            </ReferenceInput>
          </Box>
          <Box display="flex" gridGap={16}>
            <NumberInput
              fullWidth
              source="lat"
              required
              label="Latitude"
              validate={(value: number) => {
                if (value < 30) {
                  return 'Valeur incorrecte'
                }
              }}
            />
            <NumberInput
              fullWidth
              source="lon"
              required
              label="Longitude"
              validate={(value: number) => {
                if (value > 20) {
                  return 'Valeur incorrecte'
                }
              }}
            />
          </Box>
          <LinkToMaps />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box>
            <TextInput fullWidth source="etage" />
            <TextInput fullWidth source="cote" label="Intitulé d'adresse" />
            <TextInput fullWidth source="ancienne_ville" />
            <TextInput multiline fullWidth source="commentaire" />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

function LinkToMaps() {
  const { values } = useFormState<Adress>({ subscription: { values: true } })

  const { lat, lon } = values

  if (!lat || !lon) return <></>

  return (
    <Box>
      <Button
        color="primary"
        variant="contained"
        size="small"
        href={`https://www.google.com/maps/search/?api=1&z=12&query=${lat},${lon}`}
        target="_blank"
      >
        Vérifier sur google maps
      </Button>
    </Box>
  )
}
